<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VTitle from "~/components/VTitle.vue";
import QrCode from "~/components/QrCode.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import AppBonusIcon from "~/assets/svg/icons/app-bonus.svg";
import AppPartyIcon from "~/assets/svg/icons/app-party.svg";
import AppPaymentIcon from "~/assets/svg/icons/app-payment.svg";
import ButterflySVG from "~/assets/svg/butterflies/home-app-info.svg";
import yaSlotItems from "~/ya-slots/appInfo";

const appLink =
  "https://redirect.appmetrica.yandex.com/serve/1109053982455637484";

// Analytics
const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const stub = ref(false);
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:LoyaltyPromo:View");
  }
};

const onButtonClick = () => {
  send("MainPage:LoyaltyPromo:Download:Click");
  window.open(appLink, "_blank", "noopener,noreferrer");
};
</script>

<template>
  <VWrapperLocal>
    <div class="app-info-butterfly">
      <ButterflySVG />
    </div>
    <div v-if="!stub" class="app-info__yaslot">
      <VYaSlot
        :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
        :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
        :owner-id="adfoxOwnerId"
        :config="yaSlotItems"
        :analytics-sender="send"
        :analytics-view="{
          name: 'MainPage:AdFox:Promo:View',
          options: {
            placement: 'Новинки',
          },
        }"
        :analytics-click="{
          name: 'MainPage:AdFox:Promo:Click',
          options: {
            placement: 'Новинки',
          },
        }"
        @stub="stub = true"
      />
    </div>

    <div v-intersection-observer="onVisibility">
      <VTitle>Приложение Магнит</VTitle>

      <div class="app-info__items">
        <div class="app-info__item app-info__item_balance">
          <AppBonusIcon class="app-info__icon" />

          <div>
            <VText
              class="app-info__title"
              :font="{
                xs: 'body-large-accent-low',
                l: 'title-medium',
                xl: 'headline-small',
              }"
              tag="div"
            >
              Баланс бонусных накоплений
            </VText>

            <VText
              :font="{ xs: 'body-small-regular', xl: 'body-large-regular' }"
              color="secondary"
            >
              1 бонус = 1 рубль. Оплачивайте ими до 50% от{{ "\u00A0" }}суммы
              покупки
            </VText>
          </div>
        </div>
        <div class="app-info__item app-info__item_discounts">
          <AppPartyIcon class="app-info__icon" />

          <div>
            <VText
              class="app-info__title"
              :font="{
                xs: 'body-large-accent-low',
                l: 'title-medium',
                xl: 'headline-small',
              }"
              tag="div"
            >
              Следите за скидками и акциями
            </VText>

            <VText
              :font="{ xs: 'body-small-regular', xl: 'body-large-regular' }"
              color="secondary"
            >
              И получайте персональные купоны и выгодные предложения
            </VText>
          </div>
        </div>
        <div class="app-info__item app-info__item_card">
          <AppPaymentIcon class="app-info__icon" />

          <div>
            <VText
              class="app-info__title"
              :font="{
                xs: 'body-large-accent-low',
                l: 'title-medium',
                xl: 'headline-small',
              }"
              tag="div"
            >
              Карта Магнит Плюс всегда под рукой
            </VText>

            <VText
              :font="{ xs: 'body-small-regular', xl: 'body-large-regular' }"
              color="secondary"
            >
              Используйте её при оплате покупок, чтобы получать бонусы и
              Магнитики
            </VText>
          </div>
        </div>
        <div class="app-info__item app-info__item_qr">
          <div class="app-info__qr-wrapper">
            <div class="app-info__qr-box">
              <QrCode :qrcode="appLink" />
            </div>
          </div>

          <div>
            <VText
              class="app-info__title"
              :font="{
                xs: 'body-large-accent-low',
                l: 'title-medium',
                xl: 'headline-small',
              }"
              tag="div"
            >
              Скачайте приложение Магнит
            </VText>

            <VText
              :font="{ xs: 'body-small-regular', xl: 'body-large-regular' }"
              color="secondary"
            >
              Для этого просто наведите камеру на QR-код
            </VText>
          </div>
        </div>
      </div>

      <VButton class="app-info__button" full-width @click="onButtonClick">
        Скачать
      </VButton>
    </div>
  </VWrapperLocal>
</template>

<style lang="postcss" scoped>
.app-info {
  &-butterfly {
    position: relative;
    display: none;

    svg {
      position: absolute;
      right: 0;
      top: -88px;
      z-index: var(--pl-z-index-negative);
    }
  }

  &__yaslot {
    display: flex;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 48px;
    margin-bottom: 54px;

    & .disclaimer {
      bottom: var(--pl-unit-x2);
      right: var(--pl-unit-x3);
    }
  }

  &__yaslot :deep(img) {
    height: 88px !important;
    object-fit: cover;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: var(--pl-surface-muted-default);
    border-radius: 16px;
    margin-bottom: 16px;
  }

  &__item_balance {
    order: 1;
  }

  &__item_discounts {
    order: 2;
  }

  &__item_card {
    order: 3;
  }

  &__item_qr {
    display: none;
    order: 4;
    background: #f3ece1;
  }

  &__icon {
    margin-right: 14px;
    width: 80px;
    flex-shrink: 0;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__qr-wrapper {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__qr-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 108px;
    padding: 14px;
    background-color: var(--pl-global-background-primary);
    border-radius: 14px;
  }
}

@media (--pl-viewport-from-m) {
  .app-info {
    &__yaslot {
      border-radius: 20px;
      margin-bottom: 58px;

      & .disclaimer {
        right: var(--pl-unit-x5);
      }
    }

    &__yaslot :deep(img) {
      height: 104px !important;
    }

    &__item {
      border-radius: 20px;

      &_qr {
        display: flex;
      }
    }

    &__icon {
      width: 120px;
    }

    &__qr-wrapper {
      margin-right: 14px;
    }

    &__button {
      display: none;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .app-info {
    &__yaslot {
      margin-top: 64px;
      margin-bottom: 70px;
    }
  }
}

@media (--pl-viewport-from-l) {
  .app-info {
    &-butterfly {
      display: block;
    }

    &__yaslot {
      border-radius: 24px;
      margin-top: 104px;
      margin-bottom: 82px;
    }

    &__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      grid-gap: 24px;
    }

    &__item {
      margin-bottom: 0;
      border-radius: 24px;

      &_discounts {
        order: 3;
      }

      &_card {
        order: 2;
      }
    }

    &__icon {
      width: 140px;
    }

    &__qr-box {
      width: 122px;
      height: 122px;
    }

    &__qr-wrapper {
      width: 140px;
      height: 140px;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .app-info {
    &__yaslot {
      & .disclaimer {
        right: var(--pl-unit-x6);
      }
    }

    &__icon {
      width: 160px;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__qr-wrapper {
      width: 160px;
      height: 160px;
    }
  }
}
</style>
