<script lang="ts" setup>
import { vIntersectionObserver } from "@vueuse/components";
import VBlockLoader from "@magnit/core/src/components/VLoader/VBlockLoader.vue";
import VMainBanner from "~/containers/VMainBanner.vue";
import VCatalogPreview from "~/containers/VCatalogPreview.vue";
import VAuthBanner from "~/containers/VAuthBanner.vue";
import VTicker from "~/containers/VTicker.vue";
import VSpecialOffers from "~/containers/VSpecialOffers/VSpecialOffers.vue";
import VAppInfo from "~/containers/VAppInfo.vue";
import VPrivateLabels from "~/containers/VPrivateLabels.vue";
import VUsefulYaSlot from "~/containers/VUseful/VUsefulYaSlot.vue";
import VPopular from "~/containers/VPopular.vue";
import VPopularActionsSlider from "~/containers/VPopularActionsSlider.vue";
import VNovelties from "~/containers/VNovelties.vue";
import VUseful from "~/containers/VUseful/VUseful.vue";
import { useContentStore } from "~/store/content";

definePageMeta({
  layout: "default",
});

const contentStore = useContentStore();
await contentStore.requestMainPage();

// Analytics
const { send } = useAnalytics();
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:View");
  }
};
</script>

<template>
  <main v-intersection-observer="onVisibility" class="main-page">
    <VBlockLoader
      class="main-page__loader"
      :show="contentStore.status.mainPage === 'pending'"
    />

    <template v-if="contentStore.status.mainPage !== 'pending'">
      <VMainBanner />
      <VPopularActionsSlider />
      <VSpecialOffers />
      <VTicker />
      <VCatalogPreview />
      <VAuthBanner />
      <VNovelties />
      <VAppInfo />
      <VPrivateLabels />
      <VPopular />
      <VUsefulYaSlot />
      <VUseful />
      <!-- <VBrands /> -->
      <!-- <VPresentBoxes /> -->
    </template>
  </main>
</template>

<style lang="postcss">
.main-page {
  &__loader {
    height: calc(100vh - 56px);
  }
}
</style>
