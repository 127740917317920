<script setup lang="ts">
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VSkeleton from "@magnit/core/src/components/VSkeleton/VSkeleton.vue";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";

const props = withDefaults(defineProps<{ more?: boolean; }>(), { more: false });
</script>

<template>
  <VStack direction="row" wrap="nowrap" align="center">
    <VStackItem grow>
      <VHidden
        :hide="{
          xs: false,
          m: true,
        }"
      >
        <VSkeleton width="296px" height="32px" :mt="36" :mb="14" />
      </VHidden>
      <VHidden
        :hide="{
          xs: true,
          m: false,
        }"
      >
        <VSkeleton width="200px" height="32px" :mt="36" :mb="14" />
      </VHidden>
    </VStackItem>
    <VStackItem v-if="props.more">
      <VHidden
        :hide="{
          xs: false,
          m: true,
        }"
      >
        <VSkeleton width="44px" height="44px" :mt="22" />
      </VHidden>
      <VHidden
        :hide="{
          xs: true,
          m: false,
        }"
      >
        <VSkeleton width="155px" height="32px" :mt="36" :mb="14" />
      </VHidden>
    </VStackItem>
  </VStack>
</template>
